'use client'

import * as DialogWrapper from '@radix-ui/react-dialog'
import { cva } from 'class-variance-authority'
import { ReactNode, forwardRef, useCallback } from 'react'
import { animated, config, useTransition } from 'react-spring'
import { CloseButton } from '~/core/ui/CloseButton'
import { Divider } from '~/core/ui/Divider'
import { cn } from '~/core/ui/utils'

const dialogContentVariants = cva(
  'w-full max-w-[343px] z-50 mx-4 rounded-lg bg-white shadow-dialog dark:bg-gray-900 tablet:mx-0',
  {
    variants: {
      size: {
        sm: 'tablet:w-[480px] tablet:max-w-[480px]',
        md: 'tablet:w-[680px] tablet:max-w-[680px]',
        lg: 'tablet:w-[880px] tablet:max-w-[880px]'
      }
    },
    defaultVariants: {
      size: 'sm'
    }
  }
)

interface DialogProps {
  open?: boolean
  onOpenChange: (open: boolean) => void
  isPreventAutoFocusDialog?: boolean
  modal?: boolean
  className?: string
  headingClassName?: string
  dialogWrapperClassName?: string
  dialogContainer?: HTMLElement
  childrenWrapperClassName?: string
  label?: string | ReactNode
  description?: string | ReactNode

  size?: 'sm' | 'md' | 'lg'
  isDivider?: boolean
  dividerClassName?: string
  children?: ReactNode
  isHeading?: boolean
}

const Dialog = forwardRef<HTMLInputElement, DialogProps>(
  (
    {
      open = false,
      onOpenChange,
      modal = true,
      children,
      className = '',
      headingClassName = '',
      dividerClassName = '',
      dialogWrapperClassName = '',
      childrenWrapperClassName = '',
      label,
      description,
      size = 'sm',
      isDivider = false,
      isPreventAutoFocusDialog = false,
      dialogContainer,
      isHeading = true
    },
    ref
  ) => {
    const transitions = useTransition(open, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.stiff
    })

    const preventAutoFocus = useCallback((e: Event) => {
      e.preventDefault()
    }, [])

    return (
      <DialogWrapper.Root open={open} modal={modal}>
        <DialogWrapper.Portal container={dialogContainer}>
          <div
            className={cn(
              'fixed inset-0 z-50 flex h-screen items-center justify-center py-[55px]',
              dialogWrapperClassName
            )}>
            {transitions((styles, item) =>
              item ? (
                <>
                  <DialogWrapper.Overlay
                    forceMount
                    asChild
                    className="fixed inset-0 z-[49] grid place-items-center overflow-y-auto bg-gray-1000/60 transition-all duration-100 dark:bg-gray-1000/30">
                    <div>
                      <DialogWrapper.Content
                        ref={ref}
                        forceMount
                        asChild
                        onOpenAutoFocus={
                          isPreventAutoFocusDialog
                            ? preventAutoFocus
                            : undefined
                        }
                        onEscapeKeyDown={(e) => {
                          const target = e?.target as HTMLElement

                          const tagName = target?.tagName
                          const ProseMirrorContent =
                            (target?.className || '').search('ProseMirror') >= 0
                          const elm =
                            document.getElementsByClassName(
                              'clickable-blur'
                            )?.[0]

                          if (
                            ['INPUT', 'TEXTAREA'].includes(tagName) ||
                            ProseMirrorContent
                          ) {
                            target?.blur()

                            if (elm) {
                              const mouseClickEvents = [
                                'mousedown',
                                'click',
                                'mouseup'
                              ]

                              mouseClickEvents.forEach((mouseEventType) =>
                                elm.dispatchEvent(
                                  new MouseEvent(mouseEventType, {
                                    view: window,
                                    bubbles: true,
                                    cancelable: true,
                                    buttons: 1
                                  })
                                )
                              )
                            }
                          } else {
                            onOpenChange && onOpenChange(false)
                          }
                        }}
                        className={cn(
                          'my-10 h-full tablet:h-auto',
                          dialogContentVariants({ size, className })
                        )}>
                        <animated.div
                          style={{
                            opacity: styles.opacity,
                            scale: styles.opacity
                          }}>
                          {isHeading && (
                            <div
                              className={cn(
                                'px-4 pt-4 tablet:px-6 tablet:py-5',
                                headingClassName
                              )}>
                              <div className="flex items-start justify-between space-x-3">
                                <p className="flex-1 break-words text-lg font-medium text-gray-900 dark:text-gray-200">
                                  {label}
                                </p>
                                <DialogWrapper.Close
                                  aria-label="Close"
                                  className="!ml-[0px] flex h-7 items-center">
                                  <CloseButton
                                    onClick={() => onOpenChange(false)}
                                  />
                                </DialogWrapper.Close>
                              </div>

                              <div className="mt-[2px]">
                                <p className="break-words text-sm text-gray-600 dark:text-gray-300">
                                  {description}
                                </p>
                              </div>
                            </div>
                          )}
                          {isDivider ? (
                            <Divider className={cn('mb-4', dividerClassName)} />
                          ) : null}
                          <div
                            className={cn(
                              'px-4 pb-4 tablet:px-6 tablet:pb-6',
                              childrenWrapperClassName
                            )}>
                            {children}
                          </div>

                          <div
                            id="modal-append-container"
                            className="z-[1234567890]"
                          />
                        </animated.div>
                      </DialogWrapper.Content>
                    </div>
                  </DialogWrapper.Overlay>
                </>
              ) : null
            )}
          </div>
        </DialogWrapper.Portal>
      </DialogWrapper.Root>
    )
  }
)

Dialog.displayName = 'Dialog'

export { Dialog }
export type { DialogProps }
