import { gql } from 'urql'

export type QueryTagsListVariable = {
  limit?: number
  page?: number
  search?: string
}
const QueryTagsList = gql<
  {
    tagsList: {
      collection: {
        id: number
        name: string
        profilesCount: number
        value: string
      }[]
      metadata: { totalCount: number }
    }
  },
  QueryTagsListVariable
>`
  query ($limit: Int!, $page: Int!, $search: String, $sortByAlphabet: String) {
    tagsList(
      limit: $limit
      page: $page
      search: $search
      sortByAlphabet: $sortByAlphabet
    ) {
      collection {
        id
        value
        name
        profilesCount
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryTagsList
