import { useCallback, useEffect } from 'react'
import { AGENCY_TENANT } from '~/core/constants/enum'
import useQueryGraphQL from '~/core/middleware/use-query-graphQL'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import QueryAgencyCustomFieldSettingByUser from '../graphql/query-agency-custom-field-setting-by-user'
import QueryCustomFieldSettingByUser from '../graphql/query-custom-field-setting-by-user'
import {
  mappingCustomFieldKind,
  renderKeyCustomFieldForm
} from '../mapping/custom-field-mapping'
import {
  CustomFieldResponseItem,
  CustomFieldViewType
} from '../types/custom-field'

export const useCustomFieldSettingByUser = ({
  objectKind,
  employeeId,
  shouldPause = false
}: {
  objectKind: 'profile' | 'job'
  employeeId?: number
  shouldPause?: boolean
}) => {
  const { isCompanyKind } = useDetectCompanyWithKind({ kind: AGENCY_TENANT })
  const { trigger, data: response } = useQueryGraphQL({
    query: isCompanyKind
      ? QueryAgencyCustomFieldSettingByUser
      : QueryCustomFieldSettingByUser,
    variables: {
      objectKind,
      ...(employeeId ? { employeeId } : undefined),
      limit: 3456
    },
    shouldPause: true
  })

  const mappingCustomField = useCallback(
    (data?: CustomFieldResponseItem[]): CustomFieldViewType[] => {
      if (!data) return []
      return data.map((item) => ({
        id: item.id,
        index: item.index,
        type: mappingCustomFieldKind(item.fieldKind),
        name: renderKeyCustomFieldForm({
          fieldKind: item.fieldKind,
          id: item.id
        }),
        label: item.fieldName,
        roleIds: item.roleIds,
        selectOptions: (item.selectOptions || []).map((option) => ({
          value: option.key,
          supportingObj: {
            name: option.value,
            description: String(option.index)
          }
        })),
        visibility: item.visibility,
        visibleToEmployeeProfile: item.visibleToEmployeeProfile
      }))
    },
    []
  )

  useEffect(() => {
    if (!response) {
      if (!shouldPause) {
        trigger()
      }
    }
  }, [response, shouldPause])

  return {
    trigger,
    data: mappingCustomField(
      response?.customFieldSettingsByUserList?.collection
    ),
    metaData: response?.customFieldSettingsByUserList?.metadata
  }
}

export default useCustomFieldSettingByUser
