'use client'

import { InputOptionsProps } from '~/core/ui/Input'
import { cn } from '~/core/ui/utils'
import { cva } from 'class-variance-authority'
import { forwardRef } from 'react'
import PhoneInputWrapper, { CountryData } from 'react-phone-input-2'

const inputVariants = cva(
  'min-w-full flex items-center rounded justify-center w-full px-3 placeholder:text-gray-500 dark:placeholder:text-gray-400 text-gray-900 dark:text-gray-200 disabled:pointer-events-none disabled:placeholder:text-gray-400 dark:disabled:placeholder:text-gray-600 disabled:text-gray-400 dark:disabled:text-gray-600',
  {
    variants: {
      variant: {
        default:
          'outline-none border border-solid border-gray-300 focus:border-primary-300 focus:shadow-select dark:border-gray-600 dark:bg-gray-900 dark:focus:border-primary-700 dark:focus:shadow-dark-select disabled:border-gray-200 disabled:bg-gray-50 dark:disabled:border-gray-700 dark:disabled:bg-gray-800',
        destructive:
          'outline-none border border-solid border-red-300 focus:shadow-error dark:focus:shadow-dark-error dark:border-red-700 dark:bg-gray-900'
      },
      size: {
        lg: 'h-[42px] text-base',
        md: 'h-[38px] text-sm',
        sm: 'h-8 text-sm',
        xs: 'h-6 text-sm'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'md'
    }
  }
)

interface PhoneInputProps extends InputOptionsProps {
  onClear?: () => void
  value?: string
  onChange: (value: string | number) => void
  onChangeCountryCode?: (data?: CountryData) => void
  onChangeEnter?: () => void
  country?: string
  isDisabled?: boolean
  autoFocus?: boolean
  countryCodeEditable?: boolean
}

const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  ({
    value,
    isDisabled = false,
    onChange,
    onChangeCountryCode,
    onChangeEnter = undefined,
    onClear,
    size = 'sm',
    destructive = false,
    country = 'vn',
    className,
    autoFocus = false,
    countryCodeEditable = false,
    ...props
  }) => {
    return (
      <PhoneInputWrapper
        inputProps={{
          name: 'phone',
          autoFocus
        }}
        disabled={isDisabled}
        containerClass="relative"
        inputClass={cn(
          inputVariants({
            variant: destructive ? 'destructive' : 'default',
            size,
            className: 'pl-[45px]'
          })
        )}
        buttonClass="bg-transparent absolute top-0 bottom-0 p-0 [&>.selected-flag]:flex [&>.selected-flag]:items-center [&>.selected-flag]:justify-center [&>.selected-flag]:w-[38px] [&>.selected-flag]:h-full [&>.selected-flag>.flag]:absolute [&>.selected-flag>.flag]:top-1/2 [&>.selected-flag>.flag]:-mt-[5px] [&>.selected-flag>.flag]:w-4 [&>.selected-flag>.flag]:h-[11px] phone-input-arrow"
        dropdownClass={cn(
          'z-10 absolute list-none p-0 my-2.5 shadow-ats bg-white min-w-[312px] max-h-[200px] overflow-y-auto [&>li]:flex [&>li]:items-center [&>li]:space-x-2 [&>li]:text-sm [&>li:hover]:bg-gray-50 [&>li]:cursor-pointer [&>li]:py-[7px] [&>li]:px-[9px] [&>li>.flag]:w-4 [&>li>.flag]:h-[11px]',
          className
        )}
        specialLabel=""
        enableLongNumbers={true}
        country={country}
        disableDropdown={false}
        disableSearchIcon={false}
        countryCodeEditable={countryCodeEditable}
        placeholder={props.placeholder}
        value={value}
        onChange={(value, data: CountryData) => {
          onChange(value)
          onChangeCountryCode && onChangeCountryCode(data)
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && onChangeEnter) {
            onChangeEnter()
          }
        }}
      />
    )
  }
)

PhoneInput.displayName = 'PhoneInput'

export { PhoneInput }
export type { PhoneInputProps }
