'use client'

import { Editor as CoreEditor } from '@tiptap/core'
// import Bold from '@tiptap/extension-bold'
import CharacterCount from '@tiptap/extension-character-count'
import { Color } from '@tiptap/extension-color'
// import Document from '@tiptap/extension-document'
// import Dropcursor from '@tiptap/extension-dropcursor'
// import Gapcursor from '@tiptap/extension-gapcursor'
import Highlight from '@tiptap/extension-highlight'
// import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
// import { Node } from '@tiptap/core'
// import ListItem from '@tiptap/extension-list-item'
import Mention from '@tiptap/extension-mention'
// import Paragraph from '@tiptap/extension-paragraph'
import Placeholder from '@tiptap/extension-placeholder'
// import Text from '@tiptap/extension-text'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import {
  BubbleMenu,
  EditorContent,
  ReactRenderer,
  useEditor
} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { cva } from 'class-variance-authority'
import {
  ForwardRefExoticComponent,
  MouseEventHandler,
  ReactNode,
  RefAttributes,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import tippy from 'tippy.js'
import { ComboboxSelectProps } from '~/core/ui/ComboboxSelect'
import IconWrapper from '~/core/ui/IconWrapper'
import { ISelectOption, SelectOption } from '~/core/ui/Select'
import {
  cn,
  debounce,
  trimFirstContentBreakLine,
  uuidV4
} from '~/core/ui/utils'

const editorVariants = cva(
  'relative w-full max-w-full rounded outline-none px-3 prose prose-p:!my-0 dark:prose-invert focus:outline-none',
  {
    variants: {
      variant: {
        default: 'border border-solid',
        unstyled: ''
      },
      variantBorder: {
        default: 'border-gray-300 dark:border-gray-600',
        'no-default': 'border-gray-200 dark:border-gray-700',
        none: ''
      },
      size: {
        md: 'prose-base',
        sm: 'prose-sm'
      },
      destructive: {
        none: '',
        default:
          'focus:border-primary-300 focus:shadow-select dark:focus:border-primary-700 dark:focus:shadow-dark-select',
        destructive:
          'border-red-300 focus:shadow-error dark:focus:shadow-dark-error dark:border-red-700 dark:bg-gray-900'
      },
      disabled: {
        default: 'dark:bg-gray-900',
        disabled: 'pointer-events-none bg-gray-50 dark:bg-gray-800'
      },
      mode: {
        bubble: 'pt-3 pb-[22px]',
        top: 'pt-[46px] pb-[22px]',
        bottom: 'pt-3 mb-[46px]'
      }
    },
    defaultVariants: {
      size: 'md',
      variant: 'default',
      disabled: 'default',
      mode: 'bubble',
      variantBorder: 'none'
    }
  }
)

const editorMenuVariants = cva(
  'h-[38px] bg-white z-10 rounded-t absolute left-[1px] right-[1px] flex items-center px-3 space-x-4',
  {
    variants: {
      variant: {
        default: 'border-gray-300 dark:border-gray-600',
        'no-default': 'border-gray-200 dark:border-gray-700',
        none: ''
      },
      disabled: {
        default: '',
        disabled: 'pointer-events-none'
      },
      toolbarPosition: {
        top: 'top-[1px] border-b border-solid',
        bottom: '-bottom-[46px]'
      }
    },
    defaultVariants: {
      variant: 'none',
      disabled: 'default',
      toolbarPosition: 'top'
    }
  }
)

const editorBubbleMenuVariants = cva(
  'h-[38px] z-10 rounded shadow-editor bg-white absolute -bottom-full left-0 border-b border-solid flex items-center px-3 space-x-4',
  {
    variants: {
      variant: {
        default: 'border-gray-300 dark:border-gray-600',
        'no-default': 'border-gray-200 dark:border-gray-700',
        none: ''
      },
      disabled: {
        default: '',
        disabled: 'pointer-events-none'
      }
    },
    defaultVariants: {
      variant: 'none',
      disabled: 'default'
    }
  }
)

const editorPlaceholderVariants = cva(
  'before:text-gray-500 before:dark:text-gray-400 before:content-[attr(data-placeholder)] before:float-left before:h-0 before:pointer-events-none',
  {
    variants: {
      size: {
        md: 'text-base',
        sm: 'text-sm'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
)

type RichEditorSizeProps = 'md' | 'sm'

interface ExtraDropdownMenuEditorProps {
  component: {
    label: ReactNode | ((isFocus: boolean) => ReactNode)
    icon: ReactNode
  }
  source: Array<ISelectOption>
  onClick: (params: { param: ISelectOption; editor: CoreEditor }) => void
}

type IAttachmentsFile = {
  id?: string
  url?: string
  name?: string
  status?: 'pending' | 'error' | 'upload'
  statusDescription?: string
  file?: File
}

type IAttachmentsFiles = Array<IAttachmentsFile>

interface IExtraToolbar {
  attachments?: {
    show: boolean
    showPosition?: 'menuToolbar'
    itemPerRow?: string
    list?: IAttachmentsFiles
    acceptedFiles: string
    classNameItem?: string
    fileChange?: (event?: FileList | never[] | null) => void
    onDelete?: (params: { id?: string; index: number }) => void
  }
  expand?: {
    show: boolean
    isActive?: boolean
    onClick?: () => void
  }
  mentions?: {
    show: boolean
    suggestion: {
      component: ForwardRefExoticComponent<
        ComboboxSelectProps & RefAttributes<{}>
      >
      onSearch?: (query: string) => void
      componentProps?: ComboboxSelectProps
      componentPropsEditor?: ComboboxSelectProps
    }
  }
}
interface RichEditorProps {
  toolbarPosition?: 'top' | 'bottom'
  variant?: 'default' | 'unstyled'
  getElementAppendById?: string
  content?: string
  onChange?: (editor: string) => void
  isDisabled?: boolean
  className?: string
  classNameWrapper?: string
  size?: RichEditorSizeProps
  destructive?: boolean
  placeholder?: string
  limit?: number
  showCount?: boolean
  extraDropdownMenuEditor?: ExtraDropdownMenuEditorProps
  bubble?: boolean
  editorRef?: (editor: CoreEditor) => void
  showToolbar?: boolean
  extraToolbar?: IExtraToolbar
  autoFocus?: boolean
  editorMenuClassName?: string
  isNoDebounceOnChange?: boolean
}

const MenuEditor = ({
  getElementAppendById,
  editor,
  extraDropdownMenuEditor,
  extraToolbar
}: {
  editor: CoreEditor
  extraDropdownMenuEditor?: ExtraDropdownMenuEditorProps
  extraToolbar?: IExtraToolbar
  getElementAppendById?: string
}) => {
  const mentionParentRef = useRef(null)
  const [toggleOpenHeading, setToggleOpenHeading] = useState(false)
  const [toggleOpenExtraDropdown, setToggleOpenExtraDropdown] = useState(false)

  const MentionComponent = extraToolbar?.mentions?.suggestion?.component
  const refExtraDropdown = useOnclickOutside(
    () => {
      setToggleOpenExtraDropdown(false)
    },
    {
      ignoreClass: 'my-ignore-extra-dropdown'
    }
  )

  const refHeading = useOnclickOutside(
    () => {
      setToggleOpenHeading(false)
    },
    {
      ignoreClass: 'my-ignore-heading'
    }
  )

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }, [editor])

  const onClickUpload: MouseEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      event.currentTarget.value = ''
    },
    []
  )

  if (!editor) {
    return null
  }

  const fileUUID = `file-${uuidV4()}`
  return (
    <>
      <div className="flex h-full items-center justify-center">
        <div className="relative">
          <button
            type="button"
            className="my-ignore-heading flex items-center justify-center"
            onClick={() => setToggleOpenHeading(true)}>
            <IconWrapper size={14} name="Heading" />
            <IconWrapper size={14} name="ChevronDown" />
          </button>

          {toggleOpenHeading ? (
            <div
              ref={refHeading}
              className="absolute bottom-full left-0 z-50 w-[150px] pb-2.5">
              <div className="rounded bg-white shadow-ats outline-none dark:bg-gray-900 dark:shadow-dark-ats">
                <div className="flex flex-col items-start space-y-3 px-3 py-2">
                  <button
                    type="button"
                    onClick={() => {
                      //@ts-expect-error
                      editor.chain().focus().toggleHeading({ level: 2 }).run()
                      setToggleOpenHeading(false)
                    }}>
                    <p
                      className={`text-2xl ${
                        editor.isActive('heading', { level: 2 })
                          ? 'text-primary-400 dark:text-primary-400'
                          : 'text-gray-500 dark:text-gray-400'
                      } dark:text-gray-300`}>
                      Heading
                    </p>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      //@ts-expect-error
                      editor.chain().focus().setParagraph().run()
                      setToggleOpenHeading(false)
                    }}>
                    <p
                      className={`text-base ${
                        editor.isActive('paragraph')
                          ? 'text-primary-400 dark:text-primary-400'
                          : 'text-gray-500 dark:text-gray-400'
                      } dark:text-gray-300`}>
                      Heading
                    </p>
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <button
        type="button"
        //@ts-expect-error
        onClick={() => editor.chain().focus().toggleBold().run()}
        //@ts-expect-error
        disabled={!editor.can().chain().focus().toggleBold().run()}>
        <IconWrapper
          size={14}
          name="Bold"
          className={
            editor.isActive('bold')
              ? 'text-primary-400 dark:text-primary-400'
              : 'text-gray-500 dark:text-gray-400'
          }
        />
      </button>

      <button
        type="button"
        //@ts-expect-error
        onClick={() => editor.chain().focus().toggleItalic().run()}
        //@ts-expect-error
        disabled={!editor.can().chain().focus().toggleItalic().run()}>
        <IconWrapper
          size={14}
          name="Italic"
          className={
            editor.isActive('italic')
              ? 'text-primary-400 dark:text-primary-400'
              : 'text-gray-500 dark:text-gray-400'
          }
        />
      </button>

      <button type="button" onClick={setLink}>
        <IconWrapper
          size={14}
          name="Link"
          className={
            editor.isActive('link')
              ? 'text-primary-400 dark:text-primary-400'
              : 'text-gray-500 dark:text-gray-400'
          }
        />
      </button>

      <button
        type="button"
        //@ts-expect-error
        onClick={() => editor.chain().focus().toggleBulletList().run()}>
        <IconWrapper
          size={14}
          name="List"
          className={
            editor.isActive('bulletList')
              ? 'text-primary-400 dark:text-primary-400'
              : 'text-gray-500 dark:text-gray-400'
          }
        />
      </button>

      <button
        type="button"
        //@ts-expect-error
        onClick={() => editor.chain().focus().toggleOrderedList().run()}>
        <IconWrapper
          size={14}
          name="ListOrdered"
          className={
            editor.isActive('orderedList')
              ? 'text-primary-400 dark:text-primary-400'
              : 'text-gray-500 dark:text-gray-400'
          }
        />
      </button>

      <button
        type="button"
        onClick={() => {
          editor.chain().focus().unsetAllMarks().run()
          editor.chain().focus().clearNodes().run()
        }}>
        <IconWrapper size={14} name="Eraser" />
      </button>
      {extraToolbar?.attachments?.show && extraToolbar?.attachments.showPosition === 'menuToolbar' ? (
          <>
            <input
              name="file"
              id={fileUUID}
              type="file"
              multiple
              className="hidden"
              accept={extraToolbar?.attachments.acceptedFiles}
              onClick={onClickUpload}
              onChange={(event) => {
                event.preventDefault()
                const files = event.currentTarget
                  ? event.currentTarget.files
                  : []

                if (extraToolbar.attachments?.fileChange) {
                  extraToolbar.attachments.fileChange(files)
                }
              }}
            />
            <label htmlFor={fileUUID} className="cursor-pointer">
              <IconWrapper
                size={14}
                name="Paperclip"
                className="text-gray-500 dark:text-gray-400"
              />
            </label>
          </>
        ) : null}

      {extraToolbar?.expand?.show ? (
        <button
          type="button"
          onClick={() => {
            if (extraToolbar?.expand?.onClick) {
              extraToolbar.expand.onClick()
            }
          }}>
          {extraToolbar?.expand?.isActive ? (
            <IconWrapper
              size={14}
              name="Minimize2"
              className="text-primary-400 dark:text-primary-400"
            />
          ) : (
            <IconWrapper
              size={14}
              name="MoveDiagonal"
              className="text-gray-500 dark:text-gray-400"
            />
          )}
        </button>
      ) : null}

      <div
        ref={mentionParentRef}
        className="flex flex-1 items-center justify-end">
        <div className="flex items-center space-x-4">
          {extraToolbar?.attachments?.show && extraToolbar?.attachments.showPosition !== 'menuToolbar' ? (
            <>
              <input
                name="file"
                id={fileUUID}
                type="file"
                multiple
                className="hidden"
                accept={extraToolbar?.attachments.acceptedFiles}
                onClick={onClickUpload}
                onChange={(event) => {
                  event.preventDefault()
                  const files = event.currentTarget
                    ? event.currentTarget.files
                    : []

                  if (extraToolbar.attachments?.fileChange) {
                    extraToolbar.attachments.fileChange(files)
                  }
                }}
              />
              <label htmlFor={fileUUID} className="cursor-pointer">
                <IconWrapper
                  size={14}
                  name="Paperclip"
                  className={
                    extraToolbar.attachments?.list?.length
                      ? 'text-primary-400 dark:text-primary-400'
                      : 'text-gray-500 dark:text-gray-400'
                  }
                />
              </label>
            </>
          ) : null}
          {extraToolbar?.mentions?.show
            ? MentionComponent && (
                <MentionComponent
                  {...extraToolbar?.mentions?.suggestion?.componentProps}
                  {...(getElementAppendById
                    ? { portalContainer: mentionParentRef?.current }
                    : {})}
                  menuOptionAlign="end"
                  renderTrigger={() => (
                    <button type="button">
                      <IconWrapper size={14} name="AtSign" />
                    </button>
                  )}
                  onChange={(value) => {
                    const selectedMember = Array.isArray(value)
                      ? value?.[0]
                      : value
                    editor.commands.insertContent(
                      `<span data-type="mention" class="text-gray-900 font-semibold" data-id="${selectedMember?.value}" data-label="${selectedMember?.supportingObj?.name}" contenteditable="false">@${selectedMember?.supportingObj?.name}</span>`,
                      {
                        parseOptions: {
                          preserveWhitespace: false
                        }
                      }
                    )
                  }}
                />
              )
            : null}
        </div>

        {extraDropdownMenuEditor ? (
          <div className="flex items-center space-x-1">
            <div className="relative">
              <button
                type="button"
                className="my-ignore-extra-dropdown flex items-center"
                onClick={() => setToggleOpenExtraDropdown(true)}>
                {typeof extraDropdownMenuEditor.component.label === 'function'
                  ? extraDropdownMenuEditor.component.label(
                      toggleOpenExtraDropdown
                    )
                  : extraDropdownMenuEditor.component.label}
              </button>

              {toggleOpenExtraDropdown ? (
                <div
                  ref={refExtraDropdown}
                  className="absolute right-0 top-full z-50 w-[188px] pt-2.5">
                  <div className="rounded bg-white shadow-ats outline-none dark:bg-gray-900 dark:shadow-dark-ats">
                    <div className="flex  max-h-[338px] flex-col items-start overflow-y-auto p-1">
                      {(extraDropdownMenuEditor.source || []).map(
                        (item, index) => (
                          <div
                            className="w-full cursor-pointer"
                            key={index}
                            onClick={() => {
                              if (extraDropdownMenuEditor.onClick) {
                                extraDropdownMenuEditor.onClick({
                                  param: item,
                                  editor
                                })
                              }
                              setToggleOpenExtraDropdown(false)
                            }}>
                            <SelectOption
                              size="sm"
                              data={item}
                              isOption={true}
                              isHeading={false}
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            {extraDropdownMenuEditor.component.icon}
          </div>
        ) : null}
      </div>
    </>
  )
}

export const removeHTMLTagsRichEditor = (textWithHTMLTags: string) =>
  textWithHTMLTags.replace(/(<([^>]+)>)/g, '')

const RichEditor = ({
  toolbarPosition = 'top',
  variant = 'default',
  content,
  getElementAppendById,
  onChange,
  className = '',
  classNameWrapper = '',
  isDisabled = false,
  size = 'md',
  destructive = false,
  showCount = true,
  placeholder = '',
  limit = 10000,
  extraDropdownMenuEditor = undefined,
  editorRef,
  bubble = false,
  showToolbar = true,
  extraToolbar = undefined,
  autoFocus = false,
  editorMenuClassName = '',
  isNoDebounceOnChange = false
}: RichEditorProps) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptionsDebounced = useCallback(
    debounce(async (html: string) => {
      onChange && onChange(trimFirstContentBreakLine(html))
    }, 250),
    [onChange]
  )

  const getClassNameWrapper = () =>
    cn(
      editorVariants({
        variant,
        variantBorder:
          isDisabled === false || (isDisabled === true && destructive)
            ? 'default'
            : isDisabled === true && !destructive
            ? 'no-default'
            : 'none',
        size,
        disabled: isDisabled ? 'disabled' : 'default',
        destructive:
          variant === 'unstyled'
            ? 'none'
            : destructive
            ? 'destructive'
            : 'default',
        className:
          classNameWrapper ||
          className ||
          cn('min-w-[602px] max-w-full min-h-[219px]', showToolbar ? '' : 'pt-3'),
        mode: bubble ? 'bubble' : toolbarPosition
      })
    )

  const editor = useEditor({
    extensions: [
      // Document,
      // Bold,
      // Paragraph,
      // Text,
      Highlight.configure({
        multicolor: true,
        HTMLAttributes: {
          class: 'px-2 rounded-3 py-0.5 mark-attr'
        }
      }),
      Underline,
      // Image,
      // Dropcursor,
      // Gapcursor,
      // ListItem,
      Color,
      TextStyle,
      Link,
      TextAlign.configure({
        types: ['heading', 'paragraph']
      }),
      Placeholder.configure({
        placeholder,
        emptyEditorClass: cn(
          editorPlaceholderVariants({
            size
          })
        )
      }),
      CharacterCount.configure({
        // limit
        // mode: 'nodeSize'
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'text-gray-900 font-semibold'
        },
        // @ts-expect-error
        renderLabel({
          options,
          node
        }: {
          options: { suggestion: { char: string } }
          node: { attrs: { label: string; id: string } }
        }) {
          return `${options.suggestion.char}${
            node.attrs.label ?? node.attrs.id
          }`
        },

        suggestion: extraToolbar?.mentions?.show ? {
          items: ({ query }: { query: string }) => {
            extraToolbar?.mentions?.suggestion?.onSearch &&
              extraToolbar?.mentions?.suggestion?.onSearch(query)
            return []
          },
          render: () => {
            let component: any
            let popup: any
            const componentPropsEditor =
              extraToolbar?.mentions?.suggestion?.componentPropsEditor || {}

            return {
              onStart: (props: any) => {
                if (extraToolbar?.mentions?.suggestion.component)
                  component = new ReactRenderer(
                    extraToolbar?.mentions?.suggestion.component,
                    {
                      props: {
                        ...props,
                        ...componentPropsEditor,
                        onChange: (value: ISelectOption) => {
                          extraToolbar?.mentions?.suggestion
                            ?.componentPropsEditor?.onChange &&
                            extraToolbar?.mentions?.suggestion?.componentPropsEditor?.onChange(
                              value
                            )
                          props?.command({
                            label: value?.supportingObj?.name,
                            id: value?.value
                          })
                        }
                      },
                      editor: props.editor
                    }
                  )

                if (!props.clientRect) {
                  return
                }

                //@ts-ignore
                popup = tippy('body', {
                  getReferenceClientRect: props.clientRect,
                  appendTo: getElementAppendById
                    ? () => document.getElementById(getElementAppendById)
                    : 'parent',
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: 'manual',
                  placement: 'left',
                  strategy: 'fixed'
                })
                component.updateProps({
                  portalContainer: popup?.[0]?.popper
                })
              },

              onUpdate(props: any) {
                component.updateProps(props)

                if (!props.clientRect) {
                  return
                }

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect
                })
              },

              onKeyDown(props: any) {
                if (props.event.key === 'Escape') {
                  popup[0].hide()

                  return true
                }

                return component.ref?.onKeyDown(props)
              },

              onExit() {
                popup[0].destroy()
                component.destroy()
              }
            }
          }
        } : {}
      }),
      // @ts-expect-error
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false
        }
      })
    ],
    content: content ? content?.toString() : '',
    onUpdate: ({ editor }) => {
      // Update the local state with the content of the editor
      if (onChange) {
        if (isNoDebounceOnChange) {
          onChange && onChange(trimFirstContentBreakLine(editor.getHTML()))
        } else {
          loadOptionsDebounced(editor.getHTML())
        }
      }
    },
    autofocus: autoFocus ? 'end' : false,
    editable: !isDisabled,
    editorProps: {
      attributes: {
        spellcheck: 'false',
        class: getClassNameWrapper()
      }
    }
  })

  useEffect(() => {
    // handle change class in editorProps
    // issue link: https://github.com/ueberdosis/tiptap/pull/1540
    if (editor) {
      editor.setOptions({
        editorProps: {
          attributes: {
            class: getClassNameWrapper()
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classNameWrapper, className, isDisabled, destructive])

  useEffect(() => {
    if (editor && editorRef) {
      editorRef(editor)
    }
  }, [editor, editorRef])

  return (
    <div className={`relative ${className || 'min-w-[602px] max-w-full'}`}>
      {showToolbar && editor && bubble === false ? (
        <div
          className={cn(
            editorMenuVariants({
              variant:
                isDisabled === false || (isDisabled === true && destructive)
                  ? 'default'
                  : isDisabled === true && !destructive
                  ? 'no-default'
                  : 'none',
              disabled: isDisabled ? 'disabled' : 'default',
              toolbarPosition
            }),
            editorMenuClassName
          )}>
          <MenuEditor
            getElementAppendById={getElementAppendById}
            extraToolbar={extraToolbar}
            extraDropdownMenuEditor={extraDropdownMenuEditor}
            editor={editor}
          />
        </div>
      ) : null}
      {showToolbar && editor && bubble === true ? (
        <BubbleMenu
          className={cn(
            editorBubbleMenuVariants({
              variant:
                isDisabled === false || (isDisabled === true && destructive)
                  ? 'default'
                  : isDisabled === true && !destructive
                  ? 'no-default'
                  : 'none',
              disabled: isDisabled ? 'disabled' : 'default'
            })
          )}
          tippyOptions={{ duration: 100 }}
          editor={editor}>
          <MenuEditor
            getElementAppendById={getElementAppendById}
            extraToolbar={extraToolbar}
            extraDropdownMenuEditor={extraDropdownMenuEditor}
            editor={editor}
          />
        </BubbleMenu>
      ) : null}

      <EditorContent editor={editor} />
      {editor && showCount ? (
        <div
          className={cn(
            'absolute bottom-[1px] left-[1px] right-[1px] z-10 flex h-[22px] items-center justify-end px-3'
          )}>
          <p
            className={`text-sm ${
              isDisabled
                ? 'text-gray-400 dark:text-gray-600'
                : 'text-gray-500 dark:text-gray-400'
            }`}>
            {removeHTMLTagsRichEditor(editor?.getHTML()).length}/{limit}
          </p>
        </div>
      ) : null}
    </div>
  )
}

RichEditor.displayName = 'RichEditor'

export { MenuEditor, RichEditor }
export type {
  ExtraDropdownMenuEditorProps,
  IExtraToolbar,
  RichEditorProps,
  RichEditorSizeProps,
  IAttachmentsFile,
  IAttachmentsFiles
}
