'use client'

import * as DialogWrapper from '@radix-ui/react-dialog'
import { ReactNode, forwardRef, useCallback } from 'react'
import ReactDOM from 'react-dom'
import { animated, config, useTransition } from 'react-spring'
import { cn } from '~/core/ui//utils'
import { Button, ButtonProps } from '~/core/ui/Button'
import { Checkbox } from '~/core/ui/Checkbox'
import { CloseButton } from '~/core/ui/CloseButton'
import { LucideIconName } from '~/core/ui/IconWrapper'
import { TextButton } from '~/core/ui/TextButton'

interface DialogProps {
  open?: boolean
  onOpenChange: (open: boolean) => void
  isPreventAutoFocusDialog?: boolean
  modal?: boolean
  className?: string
  headingClassName?: string
  dialogWrapperClassName?: string
  label?: string
  alertDialogContainer?: HTMLElement

  children?: ReactNode
}

const DialogAlert = forwardRef<HTMLInputElement, DialogProps>(
  (
    {
      open = false,
      onOpenChange,
      modal = true,
      children,
      className = '',
      headingClassName = '',
      dialogWrapperClassName = '',
      label,
      isPreventAutoFocusDialog = true,
      alertDialogContainer
    },
    ref
  ) => {
    const transitions = useTransition(open, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: config.stiff
    })

    const preventAutoFocus = useCallback((e: Event) => {
      e.preventDefault()
    }, [])

    return (
      <DialogWrapper.Root open={open} modal={modal}>
        <DialogWrapper.Portal container={alertDialogContainer}>
          <div
            className={cn(
              'fixed inset-0 z-50 flex items-center justify-center',
              dialogWrapperClassName
            )}>
            {transitions((styles, item) =>
              item ? (
                <>
                  <DialogWrapper.Overlay
                    forceMount
                    asChild
                    className="fixed inset-0 z-[49] bg-gray-1000/60 transition-all duration-100 dark:bg-gray-1000/30">
                    <animated.div
                      style={{
                        opacity: styles.opacity
                      }}
                    />
                  </DialogWrapper.Overlay>
                  <DialogWrapper.Content
                    ref={ref}
                    forceMount
                    asChild
                    onOpenAutoFocus={
                      isPreventAutoFocusDialog ? preventAutoFocus : undefined
                    }
                    onEscapeKeyDown={(e) => onOpenChange(false)}
                    className={cn(
                      'z-50 mx-4 rounded-lg bg-white shadow-dialog dark:bg-gray-900 tablet:mx-0',
                      className
                    )}>
                    <animated.div
                      style={{
                        opacity: styles.opacity,
                        scale: styles.opacity
                      }}>
                      <div
                        className={cn(
                          'flex items-start justify-between space-x-3 px-4 pt-4 tablet:px-6 tablet:pt-5',
                          headingClassName
                        )}>
                        <p className="break-words text-lg font-medium text-gray-900 dark:text-gray-200">
                          {label}
                        </p>
                        <div className="flex h-7 items-center">
                          <CloseButton onClick={() => onOpenChange(false)} />
                        </div>
                      </div>
                      <div className="px-4 pb-4 tablet:px-6 tablet:pb-6">
                        {children}
                      </div>
                    </animated.div>
                  </DialogWrapper.Content>
                </>
              ) : null
            )}
          </div>
        </DialogWrapper.Portal>
      </DialogWrapper.Root>
    )
  }
)

DialogAlert.displayName = 'DialogAlert'

interface ItemAlertProps {
  className?: string
  buttonWrapperClassName?: string
  description?: string | ReactNode
  additionalLink?: {
    title: string
    callback?: (event: React.MouseEvent<HTMLElement>) => void
    icon?: LucideIconName
    className?: string
  }
  additionalCheckbox?: {
    className?: string
    title: string
    isChecked?: boolean
    callback: (e: { target: { checked: boolean | 'indeterminate' } }) => void
  }
  actions?: Array<ButtonProps>
  destroy?: () => void
}

const ItemAlert = ({
  description = '',
  buttonWrapperClassName,
  additionalLink,
  additionalCheckbox,
  actions = [],
  destroy = () => {}
}: ItemAlertProps) => {
  const getJustify =
    additionalLink || additionalCheckbox
      ? 'tablet:justify-between'
      : 'tablet:justify-end'
  const actionsClass = `mt-4 tablet:mt-6 ${
    actions.length > 1 || additionalCheckbox || additionalLink
      ? 'tablet:flex tablet:items-center'
      : ''
  } ${getJustify}`

  const renderAdditionalLink = () =>
    additionalLink && (
      <div className={`mb-4 tablet:mb-0 ${additionalLink.className || ''}`}>
        <TextButton
          icon="trailing"
          {...(additionalLink.icon ? { iconMenus: additionalLink.icon } : {})}
          label={additionalLink?.title}
          onClick={additionalLink?.callback}
          size="md"
          type="secondary"
        />
      </div>
    )

  const renderAdditionalCheckbox = () =>
    additionalCheckbox && (
      <div
        className={`mb-4 flex tablet:mb-0 ${
          additionalCheckbox.className || ''
        }`}>
        <Checkbox
          size="sm"
          isChecked={additionalCheckbox?.isChecked}
          text={additionalCheckbox?.title}
          onCheckedChange={additionalCheckbox?.callback}
        />
      </div>
    )

  return (
    <>
      <div className="mt-[6px]">
        {description ? (
          <div className="mt-1">
            {typeof description === 'string' ? (
              <div
                className="break-words text-sm text-gray-600 dark:text-gray-300"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            ) : (
              <div className="break-words text-sm text-gray-600 dark:text-gray-300">
                {description}
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className={actionsClass}>
        {renderAdditionalLink()}
        {renderAdditionalCheckbox()}

        <div
          className={cn(
            'grid gap-x-3',
            actions.length === 1 ? 'grid-cols-1' : 'grid-cols-2',
            buttonWrapperClassName
          )}>
          {actions.map((session, index) =>
            session?.label ? (
              <Button
                className={session.className}
                key={index}
                size={session.size}
                configurations={session.configurations}
                type={session.type}
                label={session.label}
                autoFocus={session.autoFocus}
                onClick={async (e) => {
                  if (session.onClick) {
                    await session.onClick(e)
                  }
                  destroy()
                }}
              />
            ) : (
              <div key={index} />
            )
          )}
        </div>
      </div>
    </>
  )
}

interface AlertProps {
  open?: boolean
  modal?: boolean

  // Config modal alert
  className?: string
  dialogWrapperClassName?: string
  buttonWrapperClassName?: string
  title?: string
  description?: string | ReactNode
  alertDialogContainer?: HTMLElement
  additionalLink?: {
    title: string
    callback?: (event: React.MouseEvent<HTMLElement>) => void
    icon?: LucideIconName
    className?: string
  }
  additionalCheckbox?: {
    className?: string
    title: string
    isChecked?: boolean
    callback: (e: { target: { checked: boolean | 'indeterminate' } }) => void
  }
  actions?: Array<ButtonProps>
  destroy: () => void
  isPreventAutoFocusDialog?: boolean
}

export const AlertDialog = ({
  open = true,
  modal = true,
  className = '',
  dialogWrapperClassName = '',
  buttonWrapperClassName = '',
  title = '',
  description = '',
  additionalLink,
  additionalCheckbox,
  actions = [],
  alertDialogContainer,
  isPreventAutoFocusDialog = true,
  destroy
}: AlertProps) => {
  //destroy modal alert when url is changed
  window.addEventListener('popstate', function (event) {
    destroy()
  })
  return (
    <DialogAlert
      open={open}
      onOpenChange={destroy}
      isPreventAutoFocusDialog={isPreventAutoFocusDialog}
      alertDialogContainer={alertDialogContainer}
      modal={modal}
      label={title}
      className={`w-full max-w-[343px] tablet:w-[480px] tablet:max-w-[480px] ${className}`}
      dialogWrapperClassName={dialogWrapperClassName}>
      <ItemAlert
        description={description}
        additionalLink={additionalLink}
        additionalCheckbox={additionalCheckbox}
        actions={actions}
        destroy={destroy}
        buttonWrapperClassName={buttonWrapperClassName}
      />
    </DialogAlert>
  )
}

export function openAlert({
  className,
  buttonWrapperClassName,
  title,
  description,
  actions,
  isPreventAutoFocusDialog,
  additionalLink
}: {
  className?: string
  buttonWrapperClassName?: string
  title?: string
  description?: string | ReactNode
  actions?: Array<ButtonProps>
  isPreventAutoFocusDialog?: boolean
  additionalLink?: {
    title: string
    callback?: (event: React.MouseEvent<HTMLElement>) => void
    icon?: LucideIconName
    className?: string
  }
}): void {
  const div = document.createElement('div')
  document.body.appendChild(div)
  ReactDOM.render(
    <AlertDialog
      isPreventAutoFocusDialog={isPreventAutoFocusDialog}
      buttonWrapperClassName={buttonWrapperClassName}
      className={className}
      title={title}
      description={description}
      actions={actions}
      additionalLink={additionalLink}
      destroy={() => setTimeout(() => ReactDOM.unmountComponentAtNode(div), 0)}
    />,
    div
  )
}
